import React, { Component } from 'react';
import classNames from 'classnames';

import { Divider } from '@eventbrite/eds-divider';

import ImageContainer from './ImageContainer';
import PrimaryContent from './PrimaryContent';
import SubContent from './SubContent';
import MediaCardActions from './MediaCardActions';
import ExtraContent from './ExtraContent';

import {
    LIST_TYPE,
    MEDIA_CARD_PROPTYPES,
    MINI_STYLE,
    IMAGE_STYLE_FIXED,
} from '../constants';

import './listMediaCardContent.scss';

const ExtraContentContainer = ({ imageStyle, style, extraContent }) => {
    let component = null;

    if (imageStyle === IMAGE_STYLE_FIXED && style !== MINI_STYLE) {
        component = (
            <div className="eds-show-up-sm">
                <ExtraContent extraContent={extraContent} />
            </div>
        );
    }

    return component;
};

const DividerComponent = ({ shouldShowDivider }) => {
    let component = null;

    if (shouldShowDivider) {
        component = (
            <div className="eds-media-card-content__divisor eds-l-pad-vert-4">
                <Divider color="ui-orange" />
            </div>
        );
    }

    return component;
};

const ListMediaCardContentContainer = ({
    extraContent,
    title,
    subtitle,
    onClick,
    linkUrl,
    linkAlt,
    style,
    shouldShowDivider,
    subContentOne,
    subContentTwo,
    subContentThree,
    subContentFour,
    primaryIconType,
    primaryIconStyle,
    primaryIconTitle,
    primaryIconOnClick,
    primaryIconDropdown,
    secondaryIconType,
    secondaryIconStyle,
    secondaryIconTitle,
    secondaryIconOnClick,
    shouldShowIcons,
    isConsumer,
    isTargetBlank,
    isNoFollow,
    useSmallPadding,
}) => (
    <div
        className={classNames(
            'eds-media-card-content__content-container',
            {
                'eds-media-card-content__content-container--small-padding':
                    useSmallPadding,
            },
            'eds-l-pad-right-2',
        )}
    >
        <div className="eds-media-card-content__content">
            <ExtraContent content={extraContent} />

            <div className="eds-media-card-content__content__principal">
                <PrimaryContent
                    title={title}
                    subtitle={subtitle}
                    onClick={onClick}
                    linkUrl={linkUrl}
                    linkAlt={linkAlt}
                    isConsumer={isConsumer}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    style={style}
                    type={isConsumer ? LIST_TYPE : null}
                />

                <DividerComponent shouldShowDivider={shouldShowDivider} />

                <SubContent
                    subContentOne={subContentOne}
                    subContentTwo={subContentTwo}
                    subContentThree={subContentThree}
                    subContentFour={subContentFour}
                    hasTwoActionButtons={secondaryIconType}
                    isConsumer={isConsumer}
                />
            </div>
        </div>

        <MediaCardActions
            primaryIconType={primaryIconType}
            primaryIconStyle={primaryIconStyle}
            primaryIconTitle={primaryIconTitle}
            primaryIconOnClick={primaryIconOnClick}
            primaryIconDropdown={primaryIconDropdown}
            secondaryIconType={secondaryIconType}
            secondaryIconStyle={secondaryIconStyle}
            secondaryIconTitle={secondaryIconTitle}
            secondaryIconOnClick={secondaryIconOnClick}
            shouldShowIcons={shouldShowIcons}
            isConsumer={isConsumer}
            isListCard={true}
        />
    </div>
);

export default class ListMediaCardContent extends Component {
    static propTypes = {
        ...MEDIA_CARD_PROPTYPES,
    };

    render() {
        const {
            style,
            imageStyle,
            imageUrl,
            isLazyImage,
            imageAlt,
            shouldHideImage,
            placeholderBgColor,
            onClick,
            onFocus,
            onBlur,
            linkUrl,
            title,
            subtitle,
            flag,
            flagDark,
            shouldShowDivider,
            subContentOne,
            subContentTwo,
            subContentThree,
            subContentFour,
            extraContent,
            primaryIconType,
            primaryIconStyle,
            primaryIconTitle,
            primaryIconOnClick,
            primaryIconDropdown,
            secondaryIconType,
            secondaryIconStyle,
            secondaryIconTitle,
            secondaryIconOnClick,
            shouldShowIcons,
            containerSpacingClasses,
            isConsumer,
            isTargetBlank,
            isNoFollow,
            focused,
            useSmallPadding,
        } = this.props;

        const classes = classNames(
            containerSpacingClasses,
            'eds-media-card-content',
            'eds-media-card-content--list',
            `eds-media-card-content--${style}`,
            `eds-media-card-content--${imageStyle}`,
            {
                'eds-media-card-content--focused': focused,
                'eds-l-pad-vert-3': isConsumer,
            },
        );

        let imageContainer = null;

        if (!shouldHideImage) {
            imageContainer = (
                <ImageContainer
                    onClick={onClick}
                    linkUrl={linkUrl}
                    imageUrl={imageUrl}
                    isLazyImage={isLazyImage}
                    imageAlt={imageAlt}
                    imageStyle={imageStyle}
                    flag={flag}
                    flagDark={flagDark}
                    style={style}
                    placeholderBgColor={placeholderBgColor}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    isConsumer={isConsumer}
                />
            );
        }

        return (
            <article
                className={classes}
                onFocus={onFocus}
                onBlur={onBlur}
                role="presentation"
            >
                <ExtraContentContainer
                    imageStyle={imageStyle}
                    style={style}
                    extraContent={extraContent}
                />

                {imageContainer}

                <ListMediaCardContentContainer
                    extraContent={extraContent}
                    title={title}
                    subtitle={subtitle}
                    onClick={onClick}
                    linkUrl={linkUrl}
                    linkAlt={imageAlt}
                    isConsumer={isConsumer}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    style={style}
                    shouldShowDivider={shouldShowDivider}
                    subContentOne={subContentOne}
                    subContentTwo={subContentTwo}
                    subContentThree={subContentThree}
                    subContentFour={subContentFour}
                    primaryIconType={primaryIconType}
                    primaryIconStyle={primaryIconStyle}
                    primaryIconTitle={primaryIconTitle}
                    primaryIconOnClick={primaryIconOnClick}
                    primaryIconDropdown={primaryIconDropdown}
                    secondaryIconType={secondaryIconType}
                    secondaryIconStyle={secondaryIconStyle}
                    secondaryIconTitle={secondaryIconTitle}
                    secondaryIconOnClick={secondaryIconOnClick}
                    shouldShowIcons={shouldShowIcons}
                    useSmallPadding={useSmallPadding}
                />
            </article>
        );
    }
}
