import React, { PureComponent } from 'react';
import { gettext } from '@eventbrite/i18n';

import PropTypes from 'prop-types';

import { Button } from '@eventbrite/eds-button';

export default class ActionWrapper extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        onClick: PropTypes.func,
        linkUrl: PropTypes.string,
        linkAlt: PropTypes.string,
        hasNoTabIndex: PropTypes.bool,
        isTargetBlank: PropTypes.bool,
        isNoFollow: PropTypes.bool,
    };

    render() {
        const {
            onClick,
            linkUrl,
            children,
            hasNoTabIndex,
            isTargetBlank,
            isNoFollow,
            linkAlt,
        } = this.props;
        const tabIndex = hasNoTabIndex ? '-1' : '0';
        const ariaLabel = linkAlt
            ? gettext('See more of %(linkAlt)s', { linkAlt })
            : gettext('See more');
        let aProps = {
            onClick,
            tabIndex,
            href: linkUrl,
            className: 'eds-media-card-content__action-link',
            'aria-label': ariaLabel,
        };

        if (isTargetBlank) {
            aProps = {
                ...aProps,
                target: '_blank',
                rel: 'noopener noreferrer',
            };
        }

        if (isNoFollow) {
            const prevRelProps = aProps.rel || '';

            aProps = {
                ...aProps,
                rel: `${prevRelProps} nofollow`.trim(),
            };
        }

        let component = children;

        if (linkUrl) {
            component = <a {...aProps}>{children}</a>;
        } else if (onClick) {
            const buttonProps = {
                onClick,
                tabIndex,
                style: 'none',
                size: 'block',
            };

            component = <Button {...buttonProps}>{children}</Button>;
        }

        return component;
    }
}
