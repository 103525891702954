import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ActionWrapper from './ActionWrapper';
import {
    GRID_TYPE,
    LIST_TYPE,
    HERO_STYLE,
    STYLES_PROP_TYPE,
} from '../constants';

export default class PrimaryContent extends PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        subtitle: PropTypes.node,
        onClick: PropTypes.func,
        linkUrl: PropTypes.string,
        linkAlt: PropTypes.string,
        type: PropTypes.string,
        style: STYLES_PROP_TYPE,
        isTargetBlank: PropTypes.bool,
        isNoFollow: PropTypes.bool,
        isConsumer: PropTypes.bool,
    };

    render() {
        const {
            onClick,
            linkUrl,
            linkAlt,
            title,
            type,
            subtitle,
            style,
            isTargetBlank,
            isNoFollow,
            isConsumer,
        } = this.props;
        const headingClassName = classNames(
            'eds-media-card-content__title eds-text-color--grey-800',
            {
                'eds-text-hs': style === HERO_STYLE,
                'eds-text-bl': style !== HERO_STYLE,
                'eds-text-weight--heavy': isConsumer,
            },
        );

        let subtitleClassName = 'eds-text-bs eds-text-color--grey-600';

        if (isConsumer) {
            subtitleClassName = classNames(
                'eds-text-color--primary-brand',
                'eds-l-pad-bot-1',
                'eds-text-weight--heavy',
                {
                    'eds-text-bm': type === GRID_TYPE,
                    'eds-text-bs': type === LIST_TYPE,
                },
            );
        }

        return (
            <div className="eds-media-card-content__primary-content">
                <div className={subtitleClassName}>{subtitle}</div>

                <ActionWrapper
                    onClick={onClick}
                    linkUrl={linkUrl}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    linkAlt={linkAlt}
                >
                    <h3 className={headingClassName}>{title}</h3>
                </ActionWrapper>
            </div>
        );
    }
}
