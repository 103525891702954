import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

export default class ExtraContent extends PureComponent {
    static propTypes = {
        extraContent: PropTypes.node,
    };

    render() {
        const { extraContent } = this.props;

        if (!extraContent) {
            return null;
        }

        return (
            <div className="eds-media-card-content__extra-content eds-l-pad-right-4">
                {extraContent}
            </div>
        );
    }
}
