import React, { PureComponent } from 'react';

import GridMediaCardContent from './_internal/GridMediaCardContent';
import ListMediaCardContent from './_internal/ListMediaCardContent';
import {
    LIST_TYPE,
    GRID_TYPE,
    STANDARD_STYLE,
    IMAGE_STYLE_FLUID,
    TYPES_PROP_TYPE,
    MEDIA_CARD_PROPTYPES,
    STYLES_PROP_TYPE,
    IMAGE_STYLES_PROP_TYPE,
} from './constants';

import './mediaCardContent.scss';

const CARD_CONTENT_MAP = {
    [LIST_TYPE]: ListMediaCardContent,
    [GRID_TYPE]: GridMediaCardContent,
};

export default class MediaCardContent extends PureComponent {
    static propTypes = {
        ...MEDIA_CARD_PROPTYPES,
        /**
         * The base type of card, dictates layout and content display
         */
        type: TYPES_PROP_TYPE,
        style: STYLES_PROP_TYPE,
        imageStyle: IMAGE_STYLES_PROP_TYPE,
    };

    static defaultProps = {
        type: GRID_TYPE,
        style: STANDARD_STYLE,
        imageStyle: IMAGE_STYLE_FLUID,
    };

    constructor(props) {
        super(props);

        this.state = {
            focused: props.focused,
        };
    }

    UNSAFE_componentWillReceiveProps({ focused }) {
        this.setState({ focused });
    }

    _handleOnFocus = () => {
        const { onFocus } = this.props;

        this.setState({ focused: true });

        if (onFocus) {
            onFocus();
        }
    };

    _handleOnBlur = () => {
        const { onBlur } = this.props;

        this.setState({ focused: false });

        if (onBlur) {
            onBlur();
        }
    };

    render() {
        const { type, ...mediaCardContentProps } = this.props;
        const { focused } = this.state;
        const DynamicCardContent = CARD_CONTENT_MAP[type];

        return (
            <DynamicCardContent
                {...mediaCardContentProps}
                onFocus={this._handleOnFocus}
                onBlur={this._handleOnBlur}
                focused={focused}
            />
        );
    }
}
