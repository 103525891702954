import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

export default class Flag extends PureComponent {
    static propTypes = {
        flag: PropTypes.node,
        flagDark: PropTypes.bool,
    };

    render() {
        const { flag, flagDark } = this.props;

        if (!flag) {
            return null;
        }

        return (
            <div
                className={`eds-media-card-content__flag eds-align--center eds-text-bs eds-text-color--grey-700 ${
                    flagDark ? 'eds-media-card-content__flag-dark' : ''
                }`}
            >
                {flag}
            </div>
        );
    }
}
