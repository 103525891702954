import { simpleStringHash } from '@eventbrite/string-utils';

import { FALLBACK_IMAGE_LIST } from './constants';

/*
@param {string} id - Event ID
Returns a random fallback image randomized on the given events ID
*/
export const getRandomFallbackImage = (id) =>
    FALLBACK_IMAGE_LIST[simpleStringHash(id) % FALLBACK_IMAGE_LIST.length];
