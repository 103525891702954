import React from 'react';

import Image from './Image';
import Flag from './Flag';

const ImageContainer = ({
    flag,
    flagDark,
    imageAlt,
    imageUrl,
    isLazyImage,
    linkUrl,
    onClick,
    imageStyle,
    placeholderBgColor,
    isTargetBlank,
    isNoFollow,
    isConsumer,
}) => (
    <aside className="eds-media-card-content__image-container">
        <Image
            imageUrl={imageUrl}
            onClick={onClick}
            linkUrl={linkUrl}
            isLazyImage={isLazyImage}
            imageAlt={imageAlt}
            imageStyle={imageStyle}
            hasNoTabIndex={true}
            placeholderBgColor={placeholderBgColor}
            isTargetBlank={isTargetBlank}
            isNoFollow={isNoFollow}
            isConsumer={isConsumer}
        />
        <Flag flag={flag} flagDark={flagDark} />
    </aside>
);

export default ImageContainer;
