import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType } from '@eventbrite/i18n';

import { IconButton } from '@eventbrite/eds-icon-button';
import {
    STYLE_PROP_TYPE as ICON_BUTTON_STYLE_PROPTYPE,
    ICON_PROP_TYPE as ICON_BUTTON_ICON_PROPTYPE,
} from '@eventbrite/eds-icon-button';

const MediaCardAction = ({
    iconType,
    style,
    title,
    secondary,
    onClick,
    dropdown,
    isConsumer,
    isListCard,
}) => {
    let component = null;
    const iconClassName = classNames(
        'eds-media-card-content__actions-container__action',
        {
            'eds-l-pad-all-1': !(isConsumer && isListCard),
            'eds-l-mar-right-2': secondary && !(isConsumer && isListCard),
            'eds-media-card-content__actions-icon--primary':
                !secondary && isConsumer,
            'eds-media-card-content__actions-icon--secondary':
                isConsumer && secondary,
        },
    );

    if (iconType) {
        component = (
            <span className={iconClassName}>
                <IconButton
                    title={title}
                    style={style}
                    iconType={iconType}
                    onClick={onClick}
                />
                {dropdown}
            </span>
        );
    }

    return component;
};

export default class MediaCardActions extends PureComponent {
    static propTypes = {
        primaryIconType: ICON_BUTTON_ICON_PROPTYPE,
        primaryIconStyle: ICON_BUTTON_STYLE_PROPTYPE,
        primaryIconTitle: translationPropType,
        primaryIconOnClick: PropTypes.func,
        primaryIconDropdown: PropTypes.node,
        secondaryIconType: ICON_BUTTON_ICON_PROPTYPE,
        secondaryIconStyle: ICON_BUTTON_STYLE_PROPTYPE,
        secondaryIconTitle: translationPropType,
        secondaryIconOnClick: PropTypes.func,
        shouldShowIcons: PropTypes.bool,
        isConsumer: PropTypes.bool,
        isListCard: PropTypes.bool,
    };

    render() {
        const {
            primaryIconType,
            primaryIconStyle,
            primaryIconTitle,
            primaryIconOnClick,
            primaryIconDropdown,
            secondaryIconType,
            secondaryIconStyle,
            secondaryIconTitle,
            secondaryIconOnClick,
            isConsumer,
            isListCard,
            shouldShowIcons,
        } = this.props;
        const classes = classNames(
            'eds-media-card-content__actions-container',
            {
                'eds-media-card-content__actions-container--always-visible':
                    shouldShowIcons,
                'eds-media-card-content__actions-container--consumer':
                    isConsumer,
            },
        );

        return (
            <div className={classes}>
                <MediaCardAction
                    iconType={secondaryIconType}
                    style={secondaryIconStyle}
                    title={secondaryIconTitle}
                    onClick={secondaryIconOnClick}
                    secondary={secondaryIconType}
                    isConsumer={isConsumer}
                    isListCard={isListCard}
                />

                <MediaCardAction
                    iconType={primaryIconType}
                    style={primaryIconStyle}
                    title={primaryIconTitle}
                    onClick={primaryIconOnClick}
                    dropdown={primaryIconDropdown}
                    isConsumer={isConsumer}
                    isListCard={isListCard}
                />
            </div>
        );
    }
}
