import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class SubContent extends PureComponent {
    static propTypes = {
        subContentOne: PropTypes.node,
        subContentTwo: PropTypes.node,
        subContentThree: PropTypes.node,
        subContentFour: PropTypes.node,
        subContentLast: PropTypes.node,
        isConsumer: PropTypes.bool,
        hasTwoActionButtons: PropTypes.node,
    };

    render() {
        const {
            subContentOne,
            subContentTwo,
            subContentThree,
            subContentFour,
            subContentLast,
            isConsumer,
            hasTwoActionButtons,
        } = this.props;
        const contentClassNames =
            'eds-text-bs--fixed eds-text-color--grey-600 eds-l-mar-top-1';
        const contentClassNamesLast =
            'eds-text-bs--fixed eds-text-color--grey-600 eds-l-mar-top-3';

        if (isConsumer) {
            const consumerClassNames = classNames(
                'eds-media-card-content__sub',
                'eds-text-bm',
                'eds-text-color--grey-600',
                'eds-l-mar-top-1',
                {
                    'eds-media-card-content__sub--cropped': hasTwoActionButtons,
                },
            );

            const subContent = [
                subContentOne,
                subContentTwo,
                subContentThree,
                subContentFour,
                subContentLast,
            ].map((content) => {
                let component = null;

                if (content) {
                    component = (
                        <div key={content} className={consumerClassNames}>
                            {content}
                        </div>
                    );
                }
                return component;
            });

            return (
                <div className="eds-media-card-content__sub-content">
                    {subContent}
                </div>
            );
        }

        return (
            <div className="eds-media-card-content__sub-content">
                <div className={contentClassNames}>{subContentOne}</div>

                <div className="eds-media-card-content__sub-content-cropped">
                    <div className={contentClassNames}>{subContentTwo}</div>
                    <div className={contentClassNames}>{subContentThree}</div>
                    <div className={contentClassNames}>{subContentFour}</div>
                    <div className={contentClassNamesLast}>
                        {subContentLast}
                    </div>
                </div>
            </div>
        );
    }
}
