import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

export default class LeadingContent extends PureComponent {
    static propTypes = {
        leadingContentOne: PropTypes.node,
    };

    render() {
        const { leadingContentOne } = this.props;
        const contentClassNames =
            'eds-text-bs--fixed eds-text-color--ui-600 eds-l-mar-top-1';

        return (
            <div className="eds-media-card-content__leading-content">
                <div className={contentClassNames}>{leadingContentOne}</div>
            </div>
        );
    }
}
