import React, { Component } from 'react';
import classNames from 'classnames';

import { Divider } from '@eventbrite/eds-divider';

import ImageContainer from './ImageContainer';
import PrimaryContent from './PrimaryContent';
import LeadingContent from './LeadingContent';
import SubContent from './SubContent';
import MediaCardActions from './MediaCardActions';
import ExtraContent from './ExtraContent';

import { GRID_TYPE, MEDIA_CARD_PROPTYPES, STANDARD_STYLE } from '../constants';

import './gridMediaCardContent.scss';

const DividerComponent = ({ shouldShowDivider, style }) => {
    let component = null;

    if (shouldShowDivider && style === STANDARD_STYLE) {
        component = (
            <div className="eds-media-card-content__divisor eds-l-pad-vert-4">
                <Divider color="ui-orange" />
            </div>
        );
    }

    return component;
};

const GridMediaCardContentContainer = ({
    extraContent,
    title,
    subtitle,
    onClick,
    linkUrl,
    linkAlt,
    style,
    shouldShowDivider,
    leadingContentOne,
    subContentOne,
    subContentTwo,
    subContentThree,
    subContentFour,
    subContentLast,
    primaryIconType,
    primaryIconStyle,
    primaryIconTitle,
    primaryIconOnClick,
    primaryIconDropdown,
    secondaryIconType,
    secondaryIconStyle,
    secondaryIconTitle,
    secondaryIconOnClick,
    shouldShowIcons,
    isTargetBlank,
    isNoFollow,
    isConsumer,
    useSmallPadding,
}) => (
    <div
        className={classNames('eds-media-card-content__content-container', {
            'eds-media-card-content__content-container--small-padding':
                useSmallPadding,
            'eds-media-card-content__content-container--consumer': isConsumer,
        })}
    >
        <div className="eds-media-card-content__content">
            <ExtraContent extraContent={extraContent} />

            <div className="eds-media-card-content__content__principal">
                <LeadingContent leadingContentOne={leadingContentOne} />

                <PrimaryContent
                    title={title}
                    subtitle={subtitle}
                    onClick={onClick}
                    linkUrl={linkUrl}
                    linkAlt={linkAlt}
                    style={style}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    type={isConsumer ? GRID_TYPE : null}
                    isConsumer={isConsumer}
                />

                <DividerComponent
                    shouldShowDivider={shouldShowDivider}
                    style={style}
                />

                <SubContent
                    subContentOne={subContentOne}
                    subContentTwo={subContentTwo}
                    subContentThree={subContentThree}
                    subContentFour={subContentFour}
                    subContentLast={subContentLast}
                    isConsumer={isConsumer}
                />
            </div>
        </div>

        <MediaCardActions
            primaryIconType={primaryIconType}
            primaryIconStyle={primaryIconStyle}
            primaryIconTitle={primaryIconTitle}
            primaryIconOnClick={primaryIconOnClick}
            primaryIconDropdown={primaryIconDropdown}
            secondaryIconType={secondaryIconType}
            secondaryIconStyle={secondaryIconStyle}
            secondaryIconTitle={secondaryIconTitle}
            secondaryIconOnClick={secondaryIconOnClick}
            shouldShowIcons={shouldShowIcons}
            isConsumer={isConsumer}
        />
    </div>
);

export default class GridMediaCardContent extends Component {
    static propTypes = {
        ...MEDIA_CARD_PROPTYPES,
    };

    state = {
        animatePrimaryButton: false,
    };

    _onPrimaryIconClick = () => {
        const { primaryIconOnClick, isConsumer } = this.props;

        if (isConsumer) {
            this.setState(({ animatePrimaryButton }) => ({
                animatePrimaryButton: !animatePrimaryButton,
            }));
        }

        primaryIconOnClick();
    };

    render() {
        const {
            style,
            imageStyle,
            imageUrl,
            isLazyImage,
            imageAlt,
            shouldHideImage,
            placeholderBgColor,
            onClick,
            onFocus,
            onBlur,
            linkUrl,
            title,
            subtitle,
            leadingContentOne,
            subContentOne,
            subContentTwo,
            subContentThree,
            subContentFour,
            subContentLast,
            extraContent,
            flag,
            flagDark,
            shouldShowDivider,
            primaryIconType,
            primaryIconStyle,
            primaryIconTitle,
            primaryIconDropdown,
            secondaryIconType,
            secondaryIconStyle,
            secondaryIconTitle,
            secondaryIconOnClick,
            shouldShowIcons,
            containerSpacingClasses,
            isTargetBlank,
            isNoFollow,
            isConsumer,
            focused,
            useSmallPadding,
        } = this.props;

        const { animatePrimaryButton } = this.state;

        const classes = classNames(
            containerSpacingClasses,
            'eds-media-card-content',
            'eds-media-card-content--grid',
            `eds-media-card-content--${style}`,
            `eds-media-card-content--${imageStyle}`,
            {
                'eds-media-card-content--focused': focused,
                'eds-media-card-content--animate':
                    animatePrimaryButton && isConsumer,
            },
        );

        let imageContainer = null;

        if (!shouldHideImage) {
            imageContainer = (
                <ImageContainer
                    onClick={onClick}
                    linkUrl={linkUrl}
                    imageUrl={imageUrl}
                    isLazyImage={isLazyImage}
                    imageAlt={imageAlt}
                    flag={flag}
                    flagDark={flagDark}
                    imageStyle={imageStyle}
                    placeholderBgColor={placeholderBgColor}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    isConsumer={isConsumer}
                />
            );
        }

        return (
            <article
                className={classes}
                onFocus={onFocus}
                onBlur={onBlur}
                role="presentation"
            >
                {imageContainer}

                <GridMediaCardContentContainer
                    extraContent={extraContent}
                    title={title}
                    subtitle={subtitle}
                    onClick={onClick}
                    linkUrl={linkUrl}
                    linkAlt={imageAlt}
                    isConsumer={isConsumer}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    style={style}
                    shouldShowDivider={shouldShowDivider}
                    leadingContentOne={leadingContentOne}
                    subContentOne={subContentOne}
                    subContentTwo={subContentTwo}
                    subContentThree={subContentThree}
                    subContentFour={subContentFour}
                    subContentLast={subContentLast}
                    primaryIconType={primaryIconType}
                    primaryIconStyle={primaryIconStyle}
                    primaryIconTitle={primaryIconTitle}
                    primaryIconOnClick={this._onPrimaryIconClick}
                    primaryIconDropdown={primaryIconDropdown}
                    secondaryIconType={secondaryIconType}
                    secondaryIconStyle={secondaryIconStyle}
                    secondaryIconTitle={secondaryIconTitle}
                    secondaryIconOnClick={secondaryIconOnClick}
                    shouldShowIcons={shouldShowIcons}
                    useSmallPadding={useSmallPadding}
                />
            </article>
        );
    }
}
